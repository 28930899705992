<template>
  <v-container fluid >
    <BackButton :handler="gotoFacilities"/>
    <v-container fluid style="max-width: 85%">
      <v-form ref="form" v-model="valid" lazy-validation>
        <h3  class="text-base font-semibold black-text ml-1">{{ service }} Facility Details</h3>
        <v-card class="shadow-0  bordered mt-2">
          <v-card-text >
            <v-row dense>
              <v-col md="8">
                <v-row dense>
                  <v-col md="6">
                    <label for="">Name*</label>
                    <v-text-field
                        v-model="facility.name"
                        required
                        outlined
                        background-color="#fff"
                        :rules="[(v) => !!v || 'Facility name is required']"
                        dense
                        class="q-text-field shadow-0"
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col :md="6">
                    <label for="">Name (Ar)</label>
                    <v-text-field
                        v-model="facility.ar_name"
                        outlined
                        background-color="#fff"
                        dense
                        hide-details="auto"
                        validate-on-blur
                        class="q-text-field shadow-0"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" v-if="isSurfaceEnabled">
                    <label for="">Surface*</label>

                    <v-select
                        v-model="facility.facility_surface_id"
                        :items="surfaces()"
                        item-value="id"
                        item-text="name"
                        required
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        dense
                        hide-details="auto"
                        validate-on-blur
                        class="q-autocomplete shadow-0"
                    ></v-select>
                  </v-col>

                  <v-col
                      v-if="isOutdoorEnabled"
                      :md="6"
                  >
                    <label for="">Outdoor/Indoor*</label>

                    <v-select
                        v-model="facility.is_outdoor"
                        :items="[
                      { id: '1', name: 'Outdoor' },
                      { id: '0', name: 'Indoor' },
                    ]"
                        item-value="id"
                        item-text="name"
                        required
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        :rules="[(v) => !!v || 'Ground Type is required']"
                        dense
                        hide-details="auto"
                        validate-on-blur
                        class="q-autocomplete shadow-0"
                    ></v-select>
                  </v-col>
                  <v-col
                      :md="6"
                      v-if="isGameFormationEnabled"
                  >
                    <label for="">Game Formation</label>
                    <v-autocomplete
                        item-value="id"
                        item-text="name"
                        v-model="facility.facility_game_formations"
                        :items="gameFormations()"
                        multiple
                        outlined
                        background-color="#fff"
                        dense
                        hide-details="auto"
                        validate-on-blur
                        class="q-autocomplete shadow-0"
                    >
                      <template
                          v-if="
                        facility.facility_game_formations.length ==
                          gameFormations().length
                      "
                          v-slot:selection="{ index }"
                      >
                        <span v-if="index == 0">All Formations</span>
                      </template>

                      <template v-else v-slot:selection="{ item, index }">
                        <span v-if="index == 0">{{ item.name }}</span>
                        <span v-if="index == 1">, {{ item.name }}</span>
                        <span v-if="index == 2">, {{ item.name }}</span>
                        <span v-if="index == 3">, ...</span>
                        <!--
                        <span v-if="index === 3" class="grey--text caption pl-1"
                          >and
                          {{ facility.facility_game_formations.length - 3 }}
                          others</span
                        > -->
                      </template>

                      <template
                          v-slot:prepend-item
                          v-if="gameFormations().length"
                      >
                        <v-list-item
                            ripple
                            @click="
                          toggle('gameFormations', 'facility_game_formations')
                        "
                        >
                          <v-list-item-action>
                            <v-icon
                                :color="
                              facility.facility_game_formations.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                            >{{
                                getIcon(
                                    "gameFormations",
                                    "facility_game_formations"
                                )
                              }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title
                            >All Configuration</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="perCapacity == 1" md="6">
                    <label for="">Capacity*</label>
                    <v-text-field
                        v-model="facility.capacity"
                        outlined
                        background-color="#fff"
                        required
                        dense
                        hide-details="auto"
                        validate-on-blur
                        class="q-text-field shadow-0"
                        :rules="[
                      (v) => {
                        if (!v) {
                          return 'Capacity is required';
                        }
                        if (v && isNaN(v)) {
                          return 'Capacity must be number';
                        }
                        return true;
                      },
                    ]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col v-if="perCapacity == 1" :md="6">
                    <label for="">Per</label>

                    <v-select
                        v-model="facility.capacityType"
                        :items="[
                      { id: 'slot', name: 'Time slot' },
                      { id: 'day', name: 'Day' },
                    ]"
                        item-value="id"
                        item-text="name"
                        required
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        :rules="[(v) => !!v || 'Ground Type is required']"
                        dense
                        hide-details="auto"
                        validate-on-blur
                        class="q-autocomplete shadow-0"
                    ></v-select>
                  </v-col>

                  <v-col md="6">
                    <label for="">Product Categories</label>

                    <v-select
                        item-value="id"
                        item-text="name"
                        v-model="facility.facility_categories"
                        :items="productCategories()"
                        multiple
                        required
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        dense
                        hide-details="auto"
                        validate-on-blur
                        class="q-autocomplete shadow-0"
                    ></v-select>
                  </v-col>
                  <v-col md="6">
                    <label for="">Min booking time</label>
                    <v-select
                        required
                        v-model="facility.min_booking_time"
                        hint="Min booking time"
                        item-text="text"
                        item-value="value"
                        :items="getMinBookingTimes()"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        dense
                        hide-details="auto"
                        validate-on-blur
                        class="q-autocomplete shadow-0"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="4">
                <div style="height: 250px;" class="p-l-4">
                  <image-uploader
                      @upload="
                      (data) => {
                        file = data;
                      }
                    "
                      @remove="
                      () => {
                        facility.image = null;
                      }
                    "
                      :image_path="facility.image"
                      :defaultImage="'ground'"
                      message-text=""
                      :height="290"></image-uploader>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <h3  class="text-base font-semibold black-text ml-1 mt-4">Configuration</h3>
        <v-card class="  shadow-0 bordered mt-2" >
          <v-card-text>
            <v-row dense  v-if="!isGolfEnabled" class="border-bottom">
              <v-col md="12">
                <h3  class="text-base font-semibold black-text ">Booking Based On</h3>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col v-if="!isGolfEnabled">
                <v-radio-group
                    class="d-flex"
                    v-model="perCapacity"
                    row
                    mandatory
                    :rules="[(v) => !!v || 'Type is required']"
                >
                  <v-radio
                      @click="bookingTypeChange(1, 0)"
                      label="Time"
                      value="0"
                      color="red"
                      class="custom-radio"
                  ></v-radio>
                  <v-radio
                      @click="bookingTypeChange(0, 1)"
                      label="Capacity"
                      color="red"
                      class="custom-radio"
                      value="1"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>


            <v-row dense v-if="!isGolfEnabled" class="border-bottom">
              <v-col md="12" >
                <h3  class="text-base font-semibold black-text ">Booking Configuration Settings</h3>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3" lg="3" xl="2" v-if="is_online_btn_show">
                <v-switch
                    :disabled="isOnlineBtnDisabled == true"
                    :false-value="0"
                    :true-value="1"
                    label="Online Booking"
                    v-model="facility.is_public"
                ></v-switch>
              </v-col>
              <v-col cols="3" lg="3" xl="2">
                <v-switch
                    :false-value="0"
                    :true-value="1"
                    label="Booking Approval"
                    v-model="facility.is_enable_booking_approval"
                ></v-switch>
              </v-col>
              <v-col cols="3" lg="3" xl="2" v-if="checkWritePermission($modules.facility.seasonalPricing.slug)">
                <v-switch
                    :false-value="0"
                    :true-value="1"
                    label="Assign Special Products"
                    v-model="facility.is_enable_seasonal_pricing"
                ></v-switch>
              </v-col>
              <v-col cols="3" lg="3" xl="2">
                <v-switch
                    :false-value="0"
                    :true-value="1"
                    label="Booking Reminders"
                    v-model="facility.is_enable_booking_reminder"
                ></v-switch>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>


        <v-card class="mt-4  shadow-0 bordered" v-if="!isGolfEnabled">

          <v-card-text>
            <v-row dense
                   :class="[facility.childFacility == 1 ? 'border-bottom' : '']"
            >
              <v-col cols="12">
                <div class="d-flex justify-space-between align-center">
                  <h3  class="text-base font-semibold black-text ">Dependency</h3>
                  <v-switch
                      v-if="checkWritePermission($modules.facility.management.slug)"
                      true-value="1"
                      false-value="0"
                      @change="childFacilityChange($event)"
                      v-model="facility.childFacility"
                      dense
                      hide-details="auto"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="facility.childFacility == 1" dense class="mt-4">
              <v-col
                  md="6" lg="6" xl="4"
                  v-for="(facility, di) in facility.parent_facilities"
                  :key="`pa_de_${di}`"
              >
                <dependency-widget
                    :index="di"
                    :image="facility.image"
                    :facility="facility.name"
                    :service="
                facility.facility_services.length
                  ? facility.facility_services[0].venue_service.service.name
                  : null
              "
                    :isParent="true"

                ></dependency-widget>
              </v-col>
              <v-col
                  md="6" lg="6" xl="4"
                  v-for="(facility, di) in facility.child_facilities"
                  :key="`chi_de_${di}`"
              >
                <dependency-widget
                    :index="di"
                    :image="facility.image"
                    :facility="facility.name"
                    :service="
                facility.facility_services.length
                  ? facility.facility_services[0].venue_service.service.name
                  : null
              "
                    @remove="removeChildFacility"
                ></dependency-widget>
              </v-col>
              <v-col md="6" lg="6" xl="4">
                <v-card
                    class="rounded-2 shadow-0 bordered mt-2" style="background-color: rgba(248, 250, 251, 1)"
                >
                  <v-card-text>
                    <v-row dense>
                      <v-col md="5">
                        <label for="">Service</label>
                        <v-select
                            v-on:change="getFacilities()"
                            :items="venueSerives"
                            v-model="childFacilityForm.venue_service_id"
                            item-value="venue_service_id"
                            item-text="name"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            background-color="#fff"
                            class="q-autocomplete shadow-0"
                            dense
                            hide-details="auto"
                        ></v-select>
                      </v-col>

                      <v-col md="4" xl="5">
                        <label for="">Facility</label>
                        <v-select
                            :items="facilities"
                            v-model="childFacilityForm.facility"
                            item-value="id"
                            item-text="name"
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            return-object
                            background-color="#fff"
                            class="q-autocomplete shadow-0"
                            dense
                            hide-details="auto"
                        ></v-select>
                      </v-col>

                      <v-col md="2" xl="1" v-if="checkWritePermission($modules.facility.management.slug)">
                        <v-btn color="teal-color" class="shadow-0 mt-6" dark @click="addChildFacility">
                          Add
                        </v-btn>
                      </v-col>


                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

            </v-row>
            <v-row dense  v-if="facility.childFacility == 1 && 1==2">

              <v-col md="12">
                <p class="text-neon text-base text-underline pointer " @click="showDependentFacilityForm=true">+Add Formation</p>
              </v-col>
            </v-row>


          </v-card-text>
        </v-card>


        <!-- Participant threshold email -->
        <div class="mt-4">
          <h3  class="text-base font-semibold black-text ml-1">Preferences</h3>
          <v-card  class="shadow-0  bordered mt-2">
            <v-card-text>
              <v-row v-if="perCapacity == 1" dense>
                  <v-col cols="12">
                    <div class="d-flex gap-x-10 flex-wrap align-end">
                      <v-checkbox
                          v-model="facility.is_enable_email_threshold"
                          false-value="0"
                          true-value="1"
                          label="Enable email for threshold reached"
                          :ripple="false"
                          dense

                      ></v-checkbox>

                      <div  v-if="perCapacity == 1">
                        <label for="">Limit of Online Ticket Sales*</label>
                        <v-text-field
                            v-model="facility.online_threshold"
                            outlined
                            background-color="#fff"
                            required
                            dense
                            hide-details="auto"
                            class="q-text-field shadow-0"
                            style="max-width: 165px"

                            :rules="[
                    (v) => {
                      if (!v && facility.is_enable_email_threshold == 1) {
                        return 'Limit of Online Ticket is required';
                      }
                      if (isNaN(v)) {
                        return 'Limit of Online Ticket must be number';
                      }
                      if (v && v < 1) {
                        return 'Minimum Limit of Online Ticket 1';
                      }
                      return true;
                    },
                  ]"
                        >
                          <v-tooltip slot="append" top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="primary" dark>
                                mdi-information
                              </v-icon>
                            </template>
                            <span
                            >Online booking will be disabled after the threshold
                      reached and triggered an email.</span
                            >
                          </v-tooltip>
                        </v-text-field>
                      </div>


                      <div v-if="perCapacity == 1">
                        <v-checkbox
                            v-model="facility.enable_auto_checkout"
                            false-value="0"
                            true-value="1"
                            label="Enable auto checkout"
                            dense

                        ></v-checkbox>
                      </div>


                      <div
                          v-if="perCapacity == 1 && facility.enable_auto_checkout == 1"
                      >
                        <label for="">{{`Checkout Time${perCapacity == 0 ? '*' : ''}`}}</label>
                        <v-select
                            :items="timings.slice(0, timings.length - 1)"
                            item-text="text"
                            item-value="value"
                            :rules="[(v) => !!v || 'Checkout time is required']"
                            v-model="facility.checkout_time"
                            required
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            background-color="#fff"
                            dense
                            hide-details="auto"
                            class="q-autocomplete shadow-0"
                            style="max-width: 165px"

                        ></v-select>
                      </div>

                    </div>
                  </v-col>

              </v-row>
              <v-row v-if="facility.is_enable_email_threshold == '1' && perCapacity == 1" dense>
                <v-col cols="12" sm="12" md="6">
                  <label for="">Subject*</label>

                  <v-text-field
                      v-model="facility.email_threshold_title"
                      outlined
                      background-color="#fff"
                      required
                      :rules="[
                    (v) => {
                      if (!v && facility.is_enable_email_threshold == '1') {
                        return 'Subject is required';
                      }
                      return true;
                    },
                  ]"
                      dense
                      hide-details="auto"
                      class="q-text-field shadow-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <label for="">Recipient*</label>

                  <v-text-field
                      v-model="facility.email_threshold_recipient"
                      outlined
                      background-color="#fff"
                      required
                      :rules="[
                    (v) => {
                      if (!v && facility.is_enable_email_threshold == '1') {
                        return 'Recipient is required';
                      }
                      return true;
                    },
                  ]"
                      dense
                      hide-details="auto"
                      class="q-text-field shadow-0"
                  ></v-text-field>
                </v-col>
                <div
                    class="ml-4"
                    style="margin-top: 10px"
                    v-if="perCapacity != 1"
                >
                  <span class="overline">Placeholders</span>
                  <v-tooltip top max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="grey lighten-1">mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-center">
                    Placeholder is used to add dynamic data in you message.
                    Placeholder will be replaced with actual data representing
                    each of them. Please click to copy and paste it on your
                    message
                  </span>
                  </v-tooltip>
                  <template v-for="item in placeholders">
                    <v-chip
                        :key="item.id"
                        class="mr-2 pa-4"
                        @click="copyPlaceholder(item)"
                    >
                      {{ item.name }}
                      <v-icon right small>mdi-content-copy</v-icon>
                    </v-chip>
                  </template>
                </div>
                <v-col cols="12" sm="12" md="12">
                  <text-editor
                      @complete="setEmailContent"
                      :message="facility.email_threshold_message"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-checkbox
                      v-model="facility.is_enable_email_booking"
                      false-value="0"
                      true-value="1"
                      label="Enable booking notification email"
                      :ripple="false"
                      dense
                      hide-details="auto"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="facility.is_enable_email_booking == '1'" dense>
                <v-col cols="12" sm="12" md="6">
                  <label for="">Subject*</label>
                  <v-text-field
                      v-model="facility.email_booking_title"
                      outlined
                      background-color="#fff"
                      required
                      :rules="[
                    (v) => {
                      if (!v && facility.is_enable_email_booking == '1') {
                        return 'Subject is required';
                      }
                      return true;
                    },
                  ]"
                      dense
                      hide-details="auto"
                      class="q-text-field shadow-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <label for="">Recipient*</label>
                  <v-text-field
                      v-model="facility.email_booking_recipient"
                      outlined
                      background-color="#fff"
                      required
                      :rules="[
                    (v) => {
                      if (!v && facility.is_enable_email_booking == '1') {
                        return 'Recipient is required';
                      }
                      return true;
                    },
                  ]"
                      dense
                      hide-details="auto"
                      class="q-text-field shadow-0"
                  ></v-text-field>
                </v-col>
                <div
                    class="ml-4"
                    style="margin-top: 10px"
                >
                  <span class="overline">Placeholders</span>
                  <v-tooltip top max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="grey lighten-1">mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-center">
                    Placeholder is used to add dynamic data in you message.
                    Placeholder will be replaced with actual data representing
                    each of them. Please click to copy and paste it on your
                    message
                  </span>
                  </v-tooltip>
                  <template v-for="item in placeholders">
                    <v-chip
                        :key="item.id"
                        class="mr-2 pa-4"
                        @click="copyPlaceholder(item)"
                    >
                      {{ item.name }}
                      <v-icon right small>mdi-content-copy</v-icon>
                    </v-chip>
                  </template>
                </div>
                <v-col cols="12" sm="12" md="12">
                  <text-editor
                      @complete="setBookingEmailContent"
                      :message="facility.email_booking_message"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <!-- End Participant threshold email -->


        <div class="mt-4">
          <h3  class="text-base font-semibold black-text ml-1"> Email Attachments</h3>
        </div>
        <v-card

            class=" shadow-0 bordered mt-2"
        >
          <v-card-text>



            <v-row  dense  v-for="(document, k) in facility.facility_attachments"
                    :key="'d_' + k" class="mb-2">
              <v-col cols="12" md="3">
                <label for="">Name</label>
                <v-text-field
                    required
                    outlined
                    background-color="#fff"
                    v-model="document.name"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <label for="">File</label>
                <v-file-input
                    :label="document.id != null ? '' : 'Document'"
                    v-model="document.file"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    outlined
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                >
                  <template v-slot:prepend-inner>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            color="cyan"
                            v-if="document.id"
                            @click="openFile(document.file_path)"
                            v-on="on"
                        >
                          mdi-download-box
                        </v-icon>
                        <v-icon v-else v-on="on"> mdi-paperclip </v-icon>
                      </template>
                      <span v-if="document.id">Download uploaded file</span>
                      <span v-else>Select file </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:label>
                    <span v-if="!document.id"> Select file </span>
                    <span
                        v-if="document.id && !document.file"
                        class="font-weight-bold"
                    >
                    <span
                        style="width: 100%; display: inline-block"
                        class="text-truncate"
                    >{{ document.original_file_name }}</span
                    >
                  </span>
                  </template>
                </v-file-input>
              </v-col>
              <v-col md="1" >
                <v-tooltip bottom   v-if="
                      checkDeletePermission($modules.facility.management.slug)
                    ">
                  <template v-slot:activator="{ on, attrs }">
                    <DeleteIcon  @click="deleteDocuments(k)"  style="cursor: pointer" v-bind="attrs" class="mt-6"
                                 v-on="on"    />

                  </template>
                  Delete
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <p class="text-base text-neon text-underline pointer" @click="addDocuments">+ Add Document</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="noraml-rates">

          <div class="mt-4" v-if="facility.facility_rentals.length > 0">
            <h3  class="text-base font-semibold black-text ml-1">  Rate Assignment</h3>
          </div>
          <v-card
              :style="cardStyle"
              class=" shadow-0 bordered mt-2"

          >
            <v-card-text>
              <v-row class=""  v-for="(rental, ri) in facility.facility_rentals"
                     :key="ri">

                <v-col cols="12">
                  <div class="d-flex gap-x-5 flex-wrap">
                    <div>
                      <label for="">{{perCapacity == 0 ? 'Product' : 'Ticket'}}*</label>
                      <v-autocomplete
                          required
                          :rules="[
                  (v) => (v && v.length > 0) || 'Please select products',
                ]"
                          item-text="name"
                          validate-on-blur
                          item-value="rental_product_id"
                          :items="rentalProducts().filter( (x) => x.category_name == (perCapacity == 0 ? 'Rental' : 'Ticket'))"
                          v-model="rental.rental_product_id"
                          outlined
                          background-color="#fff"
                          multiple
                          dense
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                          style="width: 300px"

                        >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" small>
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ rental.rental_product_id.length - 1 }} others)
                  </span>
                        </template>
                      </v-autocomplete>
                    </div>

                    <div>
                      <label for="">Timing Template</label>
                      <v-select
                          required
                          :items="[{ id: null, name: 'Custom' }, ...timingTemplates()]"
                          @change="timingTemplateChange(ri,'normal',$event)"
                          item-text="name"
                          item-value="id"
                          return-object
                          v-model="rental.timing_template"
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          dense
                          hide-details="auto"
                          style="width: 200px"

                          class="q-autocomplete shadow-0"
                      ></v-select>
                    </div>

                    <div>
                      <label for="">Days Applicable {{perCapacity == 0 ? '*' : ''}}</label>
                      <v-select
                          :items="weekdays"
                          item-text="name"
                          @change="rentalChange(ri, 'normal')"
                          item-value="bit_value"
                          multiple
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          validate-on-blur
                          :rules="[(v) => v.length > 0 || 'Day is required']"
                          v-model="rental.weekdays"
                          dense
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                          style="width: 250px"

                      >
                        <template
                            v-if="weekdays.length == rental.weekdays.length"
                            v-slot:selection="{ index }"
                        >
                          <span v-if="index == 0">All Days</span>
                        </template>
                        <template v-else v-slot:selection="{ item, index }">
                          <span v-if="index == 0" class="text-elepsis">{{ item.name }}</span>
                          <span v-if="index == 1" class="text-elepsis">, {{ item.name }}</span>
                          <span v-if="index == 2" class="text-elepsis">, ..</span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="toggle('rentalweekdays', ri,'normal')">
                            <v-list-item-action>
                              <v-icon
                                  :color="
                          rental.weekdays.length > 0 ? 'indigo darken-4' : ''
                        "
                              >{{ getIcon("rentalweekdays", ri,'normal') }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>All Days</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>


                    <div>
                      <label for="">From {{perCapacity == 0 ? '*' : ''}}</label>

                      <v-select
                          :items="timings.slice(0, timings.length - 1)"
                          item-text="text"
                          item-value="value"
                          @change="rentalChange(ri, 'normal')"
                          :rules="[(v) => !!v || 'From time is required']"
                          v-model="rental.start_time"
                          required
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          dense
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                          style="width: 150px"

                      ></v-select>
                    </div>


                    <div>
                      <label for="">To {{perCapacity == 0 ? '*' : ''}}</label>
                      <v-select
                          :items="timings.slice(1)"
                          item-text="text"
                          item-value="value"
                          @change="rentalChange(ri, 'normal')"
                          v-model="rental.end_time"
                          :rules="[(v) => !!v || 'To time is required']"
                          required
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          dense
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                          style="width: 150px"

                      ></v-select>
                    </div>

                    <div>
                      <DeleteIcon  @click="deleteRental(ri)"  style="cursor: pointer" class="mt-6"  />
                    </div>
                  </div>
                </v-col>



              </v-row>


              <v-row dense>
                <v-col cols="12">
                  <p @click="addFacilityRental" class="text-neon text-base text-underline pointer">+Add New</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </div>
        <div class="seasonal-rates" v-if="facility.is_enable_seasonal_pricing && checkWritePermission($modules.facility.seasonalPricing.slug)" >
          <div class=" mt-4" v-if="facility.seasonal_rentals.length > 0">
            <h3  class="text-base font-semibold black-text ml-1">  {{ perCapacity == 1 ? "Special Ticket Assignment" : "Special Product Assignment" }}</h3>
          </div>
          <v-card
              class=" shadow-0 bordered mt-2"

          >
            <v-card-text>
              <v-card class=" shadow-0 bordered mt-6"  v-for="(rental, ri) in facility.seasonal_rentals"  style="background-color: rgba(248, 250, 251, 1)"
                      :key="ri" >
                <v-card-text >
                  <v-row class="" dense >
                    <v-col xl="3" lg="3">
                      <label for="">Start Date*</label>
                      <date-field
                          v-model="rental.start_date"
                          :rules="[(v) => !!v || 'Start date is required']"
                          :backFill="true"
                          class-name="q-text-field shadow-0"
                          :dense="true"
                          label=""

                          :hide-details="true"
                      >
                      </date-field>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <label for="">End Date*</label>

                      <date-field
                          v-model="rental.end_date"
                          :minDate="rental.start_date"
                          :rules="[(v) => !!v || 'End date is required']"
                          class-name="q-text-field shadow-0"
                          :dense="true"
                          :hide-details="true"
                          label=""
                      >
                      </date-field>
                    </v-col>
                    <v-col xl="6" lg="6">
                      <label for="">{{perCapacity == 0 ? 'Products' : 'Tickets'}}*</label>

                      <v-autocomplete
                          required
                          :rules="[
                  (v) => (v && v.length > 0) || 'Please select products',
                ]"
                          item-text="name"
                          validate-on-blur
                          item-value="rental_product_id"
                          class="q-autocomplete shadow-0"
                          dense
                          hide-details="auto"
                          :items="
                  rentalProducts().filter(
                    (x) =>
                      x.category_name ==
                      (perCapacity == 0 ? 'Rental' : 'Ticket')
                  )
                "
                          v-model="rental.rental_product_id"
                          outlined
                          background-color="#fff"
                          multiple
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" small>
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ rental.rental_product_id.length - 1 }} others)
                  </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col xl="3" lg="3">
                      <label for="">Timing Template</label>
                      <v-select
                          required
                          :items="[{ id: null, name: 'Custom' }, ...timingTemplates()]"
                          @change="timingTemplateChange(ri,'seasonal', $event)"
                          item-text="name"
                          item-value="id"
                          return-object
                          v-model="rental.timing_template"
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          class="q-autocomplete shadow-0"
                          dense
                          hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <label for="">Days Applicable{{perCapacity == 0 ? '*' : ''}}</label>
                      <v-select
                          :items="weekdays"
                          item-text="name"
                          @change="rentalChange(ri, 'seasonal')"
                          item-value="bit_value"
                          multiple
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          validate-on-blur
                          :rules="[(v) => v.length > 0 || 'Day required']"
                          v-model="rental.weekdays"
                          class="q-autocomplete shadow-0"
                          dense
                          hide-details="auto"
                      >
                        <template
                            v-if="weekdays.length == rental.weekdays.length"
                            v-slot:selection="{ index }"
                        >
                          <span v-if="index == 0">All Days</span>
                        </template>
                        <template v-else v-slot:selection="{ item, index }">
                          <span v-if="index == 0">{{ item.name }}</span>
                          <span v-if="index == 1">, {{ item.name }}</span>
                          <span v-if="index == 2">, ...</span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="toggle('rentalweekdays', ri,'seasonal')">
                            <v-list-item-action>
                              <v-icon
                                  :color="
                          rental.weekdays.length > 0 ? 'indigo darken-4' : ''
                        "
                              >{{ getIcon("rentalweekdays", ri,'seasonal') }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>All Days</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <label for="">From {{perCapacity == 0 ? '*' : ''}}</label>

                      <v-select
                          :items="timings.slice(0, timings.length - 1)"
                          :label="`From${perCapacity == 0 ? '*' : ''}`"
                          item-text="text"
                          item-value="value"
                          @change="rentalChange(ri,'seasonal')"
                          :rules="[(v) => !!v || 'From time is required']"
                          v-model="rental.start_time"
                          required
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          class="q-autocomplete shadow-0"
                          dense
                          hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col xl="3" lg="3">
                      <label for="">To {{perCapacity == 0 ? '*' : ''}}</label>

                      <v-select
                          :items="timings.slice(1)"
                          item-text="text"
                          item-value="value"
                          @change="rentalChange(ri,'seasonal')"
                          v-model="rental.end_time"
                          :rules="[(v) => !!v || 'To time is required']"
                          required
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          class="q-autocomplete shadow-0"
                          dense
                          hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteSeasonalRental(ri)"
                            fab
                            x-small
                            absolute
                            top
                            icon
                            right
                            dark
                        >
                          <DeleteIcon    />
                        </v-btn>
                      </template>
                      Delete
                    </v-tooltip>
                  </v-row>
                </v-card-text>
              </v-card>


              <v-row dense class="mt-2">
                <v-col cols="12">
                  <p @click="addSeasonalRental" class="text-neon text-base text-underline pointer">+Add New</p>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </div>
      </v-form>
      <v-row>
        <v-spacer></v-spacer>

        <v-btn
            height="45"
            color="darken-1"
            class="ma-2 mt-12 white--text blue-color"
            text
            @click="addOrEditFacility"
        >{{ facility.id ? "Update" : "Save" }} Facility</v-btn
        >
      </v-row>
      <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
      ></confirm-model>
    </v-container>
  </v-container>
</template>

<script>
import moment from "moment";
import DependencyWidget from "@/components/Facility/DependencyWidget";
import TextEditor from "@/components/Marketing/TextEditor";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import BackButton from "@/components/Common/BackButton.vue";
import DeleteIcon from '@/assets/images/misc/delete-bg-icon.svg';

export default {
  components: {
    BackButton,
    ImageUploader,
    DependencyWidget,
    TextEditor,
    DeleteIcon,
  },
  data() {
    return {
      placeholders: [],
      editFlag: false,
      file: "",
      venueServiceId: null,
      service: null,
      showDependentFacilityForm:false,
      facility: {
        is_enable_seasonal_pricing:0,
        capacity: 0,
        enablePerDayCapacity: 0,
        capacityType: "slot",
        childFacility: "0",
        is_public: 0,
        is_outdoor: 0,
        is_enable_booking_approval: 0,
        is_enable_booking_reminder: 0,
        online_threshold: 0,
        is_enable_email_threshold: "0",
        is_enable_email_booking: "0",
        checkout_time: null,
        enable_auto_checkout: "0",
        email_threshold_title: "Booking Threshold Reached",
        email_threshold_message: "",
        email_booking_title: "Booking Notification",
        email_booking_message:"",
        deleted_facility_attachments: [],
        facility_attachments: [
          {
            name: null,
            file: null,
          },
        ],
        facility_rentals: [
          {
            timing_template: null,
            price: null,
            weekdays: [],
            start_time: null,
            end_time: null,
          },
        ],
        seasonal_rentals: [
          {
            timing_template: null,
            price: null,
            weekdays: [],
            start_date: null,
            end_date: null,
            start_time: null,
            end_time: null,
            is_seasonal: 1,
          },
        ],
        open_days: [],
        facility_game_formations: [],
        product_categories: [],
        child_facilities: [],
        deleted_rentals: [],
      },
      email_threshold_message: "",
      childFacilityForm: {},
      facilities: [],
      editImageHover: false,
      valid: false,
      minBookingTime: 60,
      perCapacity: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      hireProducts: [],
      timings: [],
      timeIncrement: 60,
      is_online_btn_show: 0,
      mixedConfig: false,
      isOnlineBtnDisabled: false,
      isGlobalPerCapacity: 0,
      isGolfEnabled: false,
      isGameFormationEnabled: 0,
      isSurfaceEnabled: 0,
      isOutdoorEnabled: 0,
    };
  },
  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    venueSerives() {
      return this.$store.getters.getSportsService;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },

  mounted() {
    if (this.$route.params.data) {
      let data = JSON.parse(atob(this.$route.params.data));
      this.venueServiceId = data.venue_service_id;
      this.service = data.service;
      this.initializeFacilityServices();
      if (data.facility_id != null) {
        this.facility.id = data.facility_id;
        this.getFacilitiesDetails();
      }
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
  },

  methods: {

    addDocuments() {
      this.facility.facility_attachments.push({
        name: null,
        file: null,
      });
    },
    deleteDocuments(index) {
      if (
        !this.facility.facility_attachments[index].name &&
        !this.facility.facility_attachments[index].id
      ) {
        this.facility.facility_attachments.splice(index, 1);
        if (!this.facility.facility_attachments.length) {
          this.facility.facility_attachments = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this custom field?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_document",
        };
      }
    },
    rentalChange(id,type="normal") {
      if(type == 'normal'){
        this.facility.facility_rentals[id].timing_template = null;
        this.facility.facility_rentals[id].timing_template_id = null;
      }else if(type == 'seasonal'){
        this.facility.seasonal_rentals[id].timing_template = null;
        this.facility.seasonal_rentals[id].timing_template_id = null;
      }
    },
    initializeFacilityServices() {
      if (this.timingTemplates().length == 0) {
        this.$store
          .dispatch("loadTimingTemplatesByVenueServiceId", this.venueServiceId)
          .then(() => this.$forceUpdate);
      }

      if (this.productCategories().length == 0) {
        this.$store
          .dispatch(
            "loadProductCategoriesByVenueServiceId",
            this.venueServiceId
          )
          .then(() => this.$forceUpdate);
      }
      if (this.rentalProducts().length == 0) {
        this.$store
          .dispatch("loadRentalProductsByVenueServiceId", this.venueServiceId)
          .then(() => this.$forceUpdate);
      }
      if (!this.$store.getters.getConfigurationStatus(this.venueServiceId)) {
        this.$store
          .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200) {
              let data = this.$store.getters.getConfigurationByVenueServiceId(
                this.venueServiceId
              );
              this.mixedConfig = data.mixed_config;
              this.perCapacity = data.per_capacity.toString();
              this.isGlobalPerCapacity = data.per_capacity.toString();
              if (this.isGolfEnabled) {
                this.perCapacity = "1";
                this.facility.is_public = 0;
              }
              this.is_online_btn_show = data.is_public;
              if (this.isGlobalPerCapacity == "1") {
                this.facility.is_public = 0;
                this.perCapacity = "1";
              }
              this.facility.min_booking_time =
                data.min_booking_time != null ? data.min_booking_time : 60;

              this.timeIncrement =
                data.time_increment != null ? data.time_increment : 60;

              this.isGameFormationEnabled = data.is_game_formation_enabled;
              this.isSurfaceEnabled = data.is_surface_enabled;
              this.isOutdoorEnabled = data.is_outdoor_enabled;
              this.generateTiming();
              this.$forceUpdate();
            }
          });
      } else {
        let data = this.$store.getters.getConfigurationByVenueServiceId(
          this.venueServiceId
        );
        this.mixedConfig = data.mixed_config;
        this.perCapacity = data.per_capacity.toString();
        this.isGlobalPerCapacity = data.per_capacity.toString();
        this.isGolfEnabled = data.is_golf_enabled;
        if (this.isGolfEnabled) {
          this.perCapacity = "1";
        }
        this.is_online_btn_show = data.is_public;
        if (this.isGlobalPerCapacity == "1") {
          this.facility.is_public = 0;
          this.perCapacity = "1";
        }
        this.facility.min_booking_time =
          data.min_booking_time != null ? data.min_booking_time : 60;
        this.timeIncrement =
          data.time_increment != null ? data.time_increment : 60;
        this.isGameFormationEnabled = data.is_game_formation_enabled;
        this.isSurfaceEnabled = data.is_surface_enabled;
        this.isOutdoorEnabled = data.is_outdoor_enabled;
        this.generateTiming();
        this.$forceUpdate();
      }
    },

    toggle(type, field, rental_type = 'normal') {
      this.$nextTick(() => {
        if (type == "gameFormations") {
          if (this.facility[field].length == this.gameFormations().length) {
            this.facility[field] = [];
          } else {
            this.facility[field] = this.gameFormations().map((item) => item.id);
          }
        } else if (type == "weekdays") {
          if (this.facility[field].length == this.weekdays.length) {
            this.facility[field] = [];
          } else {
            this.facility[field] = this.weekdays.map((item) => item.bit_value);
          }
        } else if (type == "rentalweekdays") {
          if(rental_type == 'normal'){
            if (this.facility.facility_rentals[field].weekdays.length == this.weekdays.length) {
              this.facility.facility_rentals[field].weekdays = [];
            } else {
              this.facility.facility_rentals[field].weekdays = this.weekdays.map((item) => item.bit_value);
            }
          }else if(rental_type == 'seasonal'){
             if (this.facility.seasonal_rentals[field].weekdays.length == this.weekdays.length) {
              this.facility.seasonal_rentals[field].weekdays = [];
            } else {
              this.facility.seasonal_rentals[field].weekdays = this.weekdays.map((item) => item.bit_value);
            }
          }
        }
      });
      this.$forceUpdate();
    },

    getIcon(type, field, rental_type = 'normal') {
      let icon = "mdi-checkbox-blank-outline";

      if (type == "gameFormations") {
        if (this.facility[field].length == this.gameFormations().length) {
          icon = "mdi-close-box";
        }
        if (
          this.facility[field].length > 0 &&
          this.facility[field].length != this.gameFormations().length
        )
          icon = "mdi-minus-box";
      } else if (type == "weekdays") {
        if (this.facility[field].length == this.weekdays.length) {
          icon = "mdi-close-box";
        }
        if (
          this.facility[field].length > 0 &&
          this.facility[field].length != this.weekdays.length
        )
          icon = "mdi-minus-box";
      } else if (type == "rentalweekdays") {
        if(rental_type == 'normal'){
          if (this.facility.facility_rentals[field].weekdays.length == this.weekdays.length) {
            icon = "mdi-close-box";
          }
          if (this.facility.facility_rentals[field].weekdays.length > 0 && this.facility.facility_rentals[field].weekdays.length != this.weekdays.length){
            icon = "mdi-minus-box";
          }
        }else if(rental_type == 'seasonal'){
          if (this.facility.seasonal_rentals[field].weekdays.length == this.weekdays.length) {
            icon = "mdi-close-box";
          }
          if (this.facility.seasonal_rentals[field].weekdays.length > 0 && this.facility.seasonal_rentals[field].weekdays.length != this.weekdays.length){
            icon = "mdi-minus-box";
          }
        }
      }
      return icon;
    },

    timingTemplateChange(index,type = 'normal',data) {
      if(type == 'normal'){
        this.facility.facility_rentals[index].timing_template_id = data.id;
        if (data.id != null) {
          this.facility.facility_rentals[index].weekdays = data.weekdays;
          this.facility.facility_rentals[index].start_time = data.start_time;
          this.facility.facility_rentals[index].end_time = data.end_time;
        } else {
          this.facility.facility_rentals[index].weekdays = [];
          this.facility.facility_rentals[index].start_time = null;
          this.facility.facility_rentals[index].end_time = null;
        }
      }else if(type == 'seasonal'){
        this.facility.seasonal_rentals[index].timing_template_id = data.id;
        if (data.id != null) {
          this.facility.seasonal_rentals[index].weekdays = data.weekdays;
          this.facility.seasonal_rentals[index].start_time = data.start_time;
          this.facility.seasonal_rentals[index].end_time = data.end_time;
        } else {
          this.facility.seasonal_rentals[index].weekdays = [];
          this.facility.seasonal_rentals[index].start_time = null;
          this.facility.seasonal_rentals[index].end_time = null;
        }
      }
    },
    getFacilitiesDetails() {
      this.showLoader("Loading factility");
      this.$http
        .get(`venues/facilities/${this.facility.id}`)
        .then((response) => {
          if (
            response.status == 200 &&
            response.data.status == true &&
            response.data.data
          ) {
            this.hideLoader();
            var data = response.data.data;
            this.facility.child_facilities = [];
            this.facility.childFacility = "0";
            this.facility.is_public = data.is_public;
            this.facility.id = data.id;
            this.facility.name = data.name;
            this.facility.ar_name = data.ar_name;
            this.facility.facility_attachments = data.facility_attachments;
            this.facility.facility_surface_id = data.facility_surface_id;
            this.facility.capacity = data.capacity;
            this.facility.image = data.image;
            this.facility.min_booking_time = data.min_booking_time;
            this.perCapacity = data.per_capacity.toString();

            if (data.is_enable_per_day_capacity) {
              console.log(data.is_enable_per_day_capacity);
              this.facility.enablePerDayCapacity = this.facility.capacityType =
                "day";
            } else {
              this.facility.capacityType = "slot";
            }

            if (this.facility.facility_attachments.length == 0) {
              this.facility.facility_attachments.push({
                name: "",
              });
            }
            this.facility.deleted_facility_attachments = [];

            this.facility.perDayCapacity = data.per_day_capacity;
            if (this.isGlobalPerCapacity != this.perCapacity) {
              this.facility.is_public = 0;
              this.isOnlineBtnDisabled = true;
            }
            this.facility.is_outdoor = data.is_outdoor.toString();
            this.facility.facility_game_formations = data.facility_game_formations.map(
              (item) => item.game_formation_id
            );
            this.facility.facility_categories = [];
            data.facility_categories.forEach((element) => {
              this.facility.facility_categories.push(element.category_id);
            });

            if (
              data.child_facilities.length > 0 ||
              data.parent_facilities.length
            ) {
              this.facility.childFacility = "1";
              this.facility.child_facilities = data.child_facilities;
              this.facility.parent_facilities = data.parent_facilities;
            }
            if (this.perCapacity == 1) {
              this.facility.open_days = data.open_days;
              this.facility.opening_time = data.opening_time;
              this.facility.closing_time = data.closing_time;
            }
            this.facility.deleted_rentals = [];
            if (data.facility_rentals.length) {
              this.facility.facility_rentals = [];
              this.facility.seasonal_rentals = [];
              data.facility_rentals.forEach((element) => {
                element.rental_product_id = [];
                if (element.facility_rental_product.length > 0 && element.is_seasonal == 0) {
                  element.rental_product_id = element.facility_rental_product.map(
                    (x) => x.rental_product_id
                  );
                }else if (element.facility_rental_product.length > 0 && element.is_seasonal == 1) {
                  element.rental_product_id = element.facility_rental_product.map(
                    (x) => x.rental_product_id
                  );
                }
                if(element.is_seasonal == 0){
                  this.facility.facility_rentals.push(element);
                }else if(element.is_seasonal == 1){
                  this.facility.seasonal_rentals.push(element);
                }
              });
              if(this.facility.seasonal_rentals.length == 0){
                this.facility.seasonal_rentals = [
                  {
                    timing_template: null,
                    price: null,
                    weekdays: [],
                    start_date: null,
                    end_date: null,
                    start_time: null,
                    end_time: null,
                    is_seasonal: 1,
                  },
                ];
              }
            } else {
              this.facility.facility_rentals = [
                {
                  timing_template: null,
                  rental_product_id: null,
                  weekdays: [],
                  start_time: null,
                  end_time: null,
                },
              ];
              // if (this.facility.is_enable_seasonal_pricing) {
              this.facility.seasonal_rentals = [
                  {
                    timing_template: null,
                    price: null,
                    weekdays: [],
                    start_date: null,
                    end_date: null,
                    start_time: null,
                    end_time: null,
                    is_seasonal: 1,
                  },
                ];
              // }
            }

            // checkout settings
            this.facility.enable_auto_checkout = data.enable_auto_checkout.toString();
            this.facility.checkout_time = data.checkout_time;
            /** threshold settings */
            this.facility.is_enable_email_threshold = data.is_enable_email_threshold.toString();
            this.facility.online_threshold = data.online_threshold;
            this.facility.email_threshold_title = data.email_threshold_title;
            this.facility.email_threshold_message = data.email_threshold_message;
            this.facility.email_threshold_recipient = data.email_threshold_recipient;
            this.email_threshold_message = data.email_threshold_message;

            this.facility.is_enable_booking_approval = data.is_enable_booking_approval;
            this.facility.is_enable_booking_reminder = data.is_enable_booking_reminder;
            this.facility.is_enable_seasonal_pricing = data.is_enable_seasonal_pricing;

            this.facility.is_enable_email_booking = data.is_enable_email_booking.toString();
            this.facility.email_booking_title = data.email_booking_title;
             this.facility.email_booking_recipient = data.email_booking_recipient;
            this.facility.email_booking_message = data.email_booking_message;
            this.email_booking_message = data.email_booking_message;

            // if (this.perCapacity != 1) {
              this.getPlaceholders();
            // }
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    copyPlaceholder(data) {
      const el = document.createElement("textarea");
      el.value = data.placeholder;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showInfo(
        "Placeholder for " + data.name + " copied to clipboard",
        2000
      );
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },

    getPlaceholders() {
      this.$http
        .get(
          "venues/marketing/messages/placeholders?type=facility_confirmation"
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.placeholders = data;
            console.log(this.placeholders);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    addOrEditFacility() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      var formData = new FormData();
      formData.append("name", this.facility.name);
      if (this.facility.ar_name){
        formData.append("ar_name", this.facility.ar_name);
      }
      if (this.facility.capacity) {
        formData.append("capacity", this.facility.capacity);
      }
      if (this.facility.capacity) {
        if (this.facility.capacityType == "day") {
          formData.append("is_enable_per_day_capacity", 1);
        }
        if (this.facility.capacityType == "slot") {
          formData.append("is_enable_per_day_capacity", 0);
        }
        if (this.facility.online_threshold) {
          if (
            parseInt(this.facility.online_threshold) >
            parseInt(this.facility.capacity)
          ) {
            this.showError("Participant threshold greater than capacity");
            return false;
          }
          formData.append(
            "online_threshold",
            parseInt(this.facility.online_threshold)
          );
        }
        if (this.facility.enable_auto_checkout == 1) {
          formData.append(
            "enable_auto_checkout",
            parseInt(this.facility.enable_auto_checkout)
          );
          formData.append("checkout_time", this.facility.checkout_time);
        } else {
          formData.append(
            "enable_auto_checkout",
            parseInt(this.facility.enable_auto_checkout)
          );
          formData.append("checkout_time", null);
        }
      }
      if (this.facility.is_enable_email_threshold) {
        formData.append(
          `is_enable_email_threshold`,
          this.facility.is_enable_email_threshold
        );
        if (this.facility.is_enable_email_threshold == 1) {
          formData.append(
            `email_threshold_recipient`,
            this.facility.email_threshold_recipient
          );
          formData.append(
            `email_threshold_title`,
            this.facility.email_threshold_title
          );
          formData.append(
            `email_threshold_message`,
            this.email_threshold_message
          );
        }
      }
       if (this.facility.is_enable_email_booking) {
        formData.append(
          `is_enable_email_booking`,
          this.facility.is_enable_email_booking
        );
        if (this.facility.is_enable_email_booking == 1) {
          formData.append(
            `email_booking_recipient`,
            this.facility.email_booking_recipient
          );
          formData.append(
            `email_booking_title`,
            this.facility.email_booking_title
          );
          formData.append(
            `email_booking_message`,
            this.email_booking_message
          );
        }
      }
      if (this.facility.facility_surface_id) {
        formData.append(
          "facility_surface_id",
          this.facility.facility_surface_id
        );
      }
      if (this.facility.opening_time) {
        formData.append("opening_time", this.facility.opening_time);
      }
      if (this.facility.closing_time) {
        formData.append("closing_time", this.facility.closing_time);
      }
      if (this.facility.open_days) {
        this.facility.open_days.forEach((day, index) => {
          formData.append(`open_days[${index}]`, day);
        });
      }


      formData.append("is_outdoor", this.facility.is_outdoor);
      formData.append("per_capacity", this.perCapacity);
      formData.append("min_booking_time", this.facility.min_booking_time);
      formData.append("facility_services[0]", this.venueServiceId);
      formData.append("is_public", this.facility.is_public);
      formData.append("is_enable_booking_approval",this.facility.is_enable_booking_approval);
      formData.append("is_enable_booking_reminder",this.facility.is_enable_booking_reminder);
      formData.append("is_enable_seasonal_pricing",this.facility.is_enable_seasonal_pricing);
      if (
        this.facility.facility_categories != null &&
        this.facility.facility_categories.length > 0
      ) {
        this.facility.facility_categories.forEach((element, index) => {
          formData.append(`facility_categories[${index}]`, element);
        });
      }
      let rental_products_ids = [];
      if (this.facility.facility_rentals.length > 0) {
        this.facility.facility_rentals.forEach((element, index) => {
          if (
            element.rental_product_id &&
            element &&
            element.weekdays.length &&
            element.start_time &&
            element.end_time
          ) {
            if (element.timing_template_id) {
              formData.append(
                `facility_rentals[${index}][timing_template_id]`,

                element.timing_template_id
              );
            }
            if (element.id) {
              formData.append(`facility_rentals[${index}][id]`, element.id);
            }
            element.rental_product_id.forEach(
              (rentalProduct, rentalProductIndex) => {
                formData.append(
                  `facility_rentals[${index}][rental_products][${rentalProductIndex}]`,
                  rentalProduct
                );
                rental_products_ids.push(rentalProduct);
              }
            );

            formData.append(
              `facility_rentals[${index}][start_time]`,
              element.start_time
            );
            formData.append(
              `facility_rentals[${index}][end_time]`,
              element.end_time
            );
            formData.append(
              `facility_rentals[${index}][weekdays]`,
              JSON.stringify(element.weekdays)
            );
          }
        });
      }

      let seasonal_products_ids = [];
      if (this.facility.seasonal_rentals.length > 0) {
        this.facility.seasonal_rentals.forEach((element, index) => {
          if (
            element && element.start_date && element.end_date &&
            element.rental_product_id && element.weekdays.length &&
            element.start_time && element.end_time
          ) {
            if (element.timing_template_id) {
              formData.append(`seasonal_rentals[${index}][timing_template_id]`,element.timing_template_id);
            }
            if (element.id) {
              formData.append(`seasonal_rentals[${index}][id]`, element.id);
            }
            element.rental_product_id.forEach(
              (rentalProduct, rentalProductIndex) => {
                formData.append(
                  `seasonal_rentals[${index}][rental_products][${rentalProductIndex}]`,
                  rentalProduct
                );
                seasonal_products_ids.push(rentalProduct);
              }
            );
            formData.append(`seasonal_rentals[${index}][start_date]`,element.start_date);
            formData.append(`seasonal_rentals[${index}][end_date]`,element.end_date);
            formData.append(`seasonal_rentals[${index}][start_time]`,element.start_time);
            formData.append(`seasonal_rentals[${index}][end_time]`,element.end_time);
            formData.append(`seasonal_rentals[${index}][weekdays]`,JSON.stringify(element.weekdays));
          }
        });
      }

      if (seasonal_products_ids.length) {
        let uniq = [...new Set(seasonal_products_ids)];
        formData.append("seasonal_products_ids", uniq);
      }

      if (rental_products_ids.length) {
        let uniq = [...new Set(rental_products_ids)];
        formData.append("rental_products_ids", uniq);
      }


      if (this.facility.facility_game_formations.length > 0) {
        this.facility.facility_game_formations.forEach((element, index) => {
          formData.append(`facility_game_formations[${index}]`, element);
        });
      }

      if (this.facility.childFacility == "0") {
        this.facility.deleted_child_facilities = [];
        this.facility.deleted_child_facilities = this.facility.child_facilities.map(
          (x) => x.id
        );
      }

      if (
        this.facility.child_facilities.length > 0 &&
        this.facility.childFacility == "1"
      ) {
        this.facility.child_facilities.forEach((element, index) => {
          formData.append(`child_facilities[${index}]`, element.id);
        });
      }

      if (
        this.facility.deleted_child_facilities != null &&
        this.facility.deleted_child_facilities.length
      ) {
        this.facility.deleted_child_facilities.forEach((id, index) => {
          formData.append(`deleted_child_facilities[${index}]`, id);
        });
      }

      if (this.facility.deleted_rentals.length > 0) {
        this.facility.deleted_rentals.forEach((id, index) => {
          formData.append(`deleted_rentals[${index}]`, id);
        });
      }

      if (this.file) {
        formData.append("image", this.file);
      }

      if (this.facility.facility_attachments.length) {
        this.facility.facility_attachments.forEach((item, index) => {
          if (item.name && item.file) {
            for (let key in item) {
              formData.append(
                `facility_attachments[${index}][${key}]`,
                item[key]
              );
            }
          }
        });
      }
      /** check if enable booking approval */

      // if (this.facility.is_enable_email_threshold == 1) {
      //   if (this.perCapacity == 0) {
      //     formData.append("online_threshold", 1);
      //   }
      // }
      this.showLoader();
      this.$http({
        method: "post",
        data: formData,
        url: `venues/facilities${
          this.facility.id != null ? "/" + this.facility.id : ""
        }`,
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.showSuccess(
              this.facility.id
                ? "Facility successfully updated"
                : "Facility successfully created"
            );

            this.$store
              .dispatch(
                "loadConfigurationsByVenueServiceId",
                this.venueServiceId
              )
              .then(() => {
                if(!this.facility.id){
                  this.facility.id = response.data.data.id;
                }
               if(this.facility.id){
                 this.getFacilitiesDetails()
               }
              });

            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },
    getFacilities() {
      this.showLoader("Loading Facilities");
      this.facilities = [];
      this.$http
        .get(
          `venues/facilities/children?venue_service_id=${
            this.childFacilityForm.venue_service_id
          }${
            this.facility.id != null
              ? "&current_facility_id=" + this.facility.id
              : ""
          }`
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.facilities = response.data.data;
            if (this.facilities.length == 0) {
              this.showError("No facilities available of selected service");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addChildFacility() {
      if (!this.childFacilityForm.facility){
        this.showError("Please fill all fields");
        return;
      }
      this.facility.child_facilities.push(this.childFacilityForm.facility);
      this.childFacilityForm = {};
      // this.showDependentFacilityForm = false;
    },
    removeChildFacility(index) {
      this.facility.child_facilities[index].id;
      if (this.facility.deleted_child_facilities == null) {
        this.facility.deleted_child_facilities = [];
      }
      this.facility.deleted_child_facilities.push(
        this.facility.child_facilities[index].id
      );
      this.facility.child_facilities.splice(index, 1);
    },
    childFacilityChange(val) {
      if (val == 1 && this.facility.child_facilities.length == 0) {
        this.facility.child_facilities = [];
        this.$forceUpdate();
      }
    },
    gameFormations() {
      return this.$store.getters.getGameFormationsByVenueServiceId(
        this.venueServiceId
      );
    },
    timingTemplates() {
      return this.$store.getters.getTimingTemplatesByVenueServiceId(
        this.venueServiceId
      );
    },
    surfaces() {
      return this.$store.getters.getFacilitySurfaceByVenueServiceId(
        this.venueServiceId
      );
    },
    productCategories() {
      return this.$store.getters.getProductCategoriesByVenueServiceId(
        this.venueServiceId
      );
    },
    rentalProducts() {
      return this.$store.getters.getRentalProductsByVenueServiceId(
        this.venueServiceId
      );
    },
    deleteRental(index) {
      let pkg = this.facility.facility_rentals[index];
      if (
        pkg.id == null &&
        pkg.name == null &&
        pkg.price == null &&
        pkg.tax_type_id == null &&
        pkg.weekdays.length == 0 &&
        pkg.start_time == null &&
        pkg.end_time == null
      ) {
        this.facility.facility_rentals.splice(index, 1);
        if (this.facility.facility_rentals.length == 0) {
          this.addFacilityRental();
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this rental?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "rental",
          data: { id: pkg.id,type:'normal' },
        };
      }
    },
    deleteSeasonalRental(index) {
      let pkg = this.facility.seasonal_rentals[index];
      if (
        pkg.id == null &&
        pkg.name == null &&
        pkg.price == null &&
        pkg.tax_type_id == null &&
        pkg.weekdays.length == 0 &&
        pkg.start_time == null &&
        pkg.end_time == null

      ) {
        this.facility.seasonal_rentals.splice(index, 1);
        if (this.facility.seasonal_rentals.length == 0) {
          this.addSeasonalRental();
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this rental?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "rental",
          data: { id: pkg.id,type:'seasonal' },
        };
      }
    },

    bookingTypeChange(oldVal, newVal) {
      if (this.facility.facility_rentals.length > 0) {
        setTimeout(() => {
          this.perCapacity = oldVal.toString();
        });
        this.$forceUpdate();
        this.confirmModel = {
          id: newVal,
          title: "Do you want to delete all rental?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "capacity",
        };
      } else {
        if (this.isGlobalPerCapacity == this.perCapacity) {
          this.isOnlineBtnDisabled = false;
        } else {
          this.isOnlineBtnDisabled = true;
          this.facility.is_public = 0;
        }
      }
    },

    confirmActions(data) {
      if (data.type == "delete_document") {
        if (this.facility.facility_attachments[data.id].id != null) {
          this.facility.deleted_facility_attachments.push(
            this.facility.facility_attachments[data.id].id
          );
        }
        this.facility.facility_attachments.splice(data.id, 1);
        if (!this.facility.facility_attachments.length) {
          this.facility.facility_attachments = [{}];
        }
      } else if (data.type == "rental") {
        if (data.data.id != null) {
          this.facility.deleted_rentals.push(data.data.id);
        }
        if(data.data.type == "normal"){
          this.facility.facility_rentals.splice(data.id, 1);
          if (this.facility.facility_rentals.length == 0) {
            this.addFacilityRental();
          }
        }else if(data.data.type == "seasonal"){
          this.facility.seasonal_rentals.splice(data.id, 1);
          if (this.facility.seasonal_rentals.length == 0) {
            this.addSeasonalRental();
          }
          return;
        }
      } else if (data.type == "capacity") {
        // console.log(this.mixedConfig);
        this.facility.deleted_rentals = this.facility.facility_rentals
          .map((x) => x.id)
          .filter((n) => n);

        setTimeout(() => {
          this.perCapacity = data.id.toString();
          this.facility.facility_rentals = [];
          if (this.mixedConfig) {
            this.facility.facility_rentals = [
              {
                timing_template: null,
                price: null,
                weekdays: [],
                start_time: null,
                end_time: null,
              },
            ];
          }
          this.$refs.form.resetValidation();
        });
      }
      if (this.isGlobalPerCapacity != this.perCapacity) {
        this.isOnlineBtnDisabled = false;
      } else {
        this.isOnlineBtnDisabled = true;
        this.facility.is_public = 0;
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    addFacilityRental() {
      this.facility.facility_rentals.push({
        timing_template: null,
        duration: null,
        price: null,
        weekdays: [],
        start_time: null,
        end_time: null,
      });
      this.$forceUpdate();
    },
    addSeasonalRental() {
      this.facility.seasonal_rentals.push({
        timing_template: null,
        duration: null,
        price: null,
        weekdays: [],
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        is_seasonal: 1,
      });
      this.$forceUpdate();
    },
    gotoFacilities() {
      this.$router.push({
        name: "FacilityPerService",
        params: {
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
        },
      });
    },
    generateTiming() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.timings = [];
      this.timings.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(this.timeIncrement, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.timings.push(data);
          hour = 24;
        } else {
          this.timings.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
    getMinBookingTimes() {
      let times = [];
      if (this.timeIncrement) {
        for (let index = 1; index <= 14; index++) {
          let time = this.timeIncrement * index;
          let hour = parseInt(time / 60);
          let min = time / 60 - hour > 0 ? (time / 60 - hour) * 60 : 0;
          let text = hour > 0 ? `${hour} Hour ` : "";
          text += min > 0 ? `${Math.round(min)} Min` : "";
          times.push({ value: time, text: text });
        }
      }

      if (this.perCapacity == 1) {
        const hasObject = times.some(
            (obj) => obj.value === 1 && obj.text === "No Duration"
        );

        // If the object doesn't exist, add it to the array
        if (!hasObject) {
          times.unshift({ value: 1, text: "No Duration" });
        }
      }

      return times;
    },
    setEmailContent(content) {
      this.email_threshold_message = content;
    },
    setBookingEmailContent(content){
    this.email_booking_message = content;
    }
  },
};
</script>

<style>
.capacity-type {
  background-color: #7c8080c4;
  cursor: pointer;
  height: 45px;
  width: 95px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  transition: "border-radius 200ms ease-in-out";
  border-radius: 4px;
  text-align: center;
  border: 1px solid #9e9e9e;
}

.capacity_class .v-input__append-inner {
  margin-top: 5px !important;
}

label{
  color: #565C68 !important;
}

.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 50% !important;
  overflow: hidden;
}
</style>
